<template>
  <div
    class="select-dropdown"
    :data-open="state"
    :data-invalid="invalid"
    :data-variant="variant"
  >

    <label v-if="label">{{ label }}</label>
    <div ref="toggle" class="toggle" @click="toggleMenu">
      <div v-if="value.color" class="color" :style="{ background: value.color }"></div>
      <input
        :value="searchString"
        class="search-dropdown"
        type="text"
        :placeholder="placeholder"
        @focus="focusSearch = true"
        @blur="focusSearch = false"
        @input="onSearch"
      />
      <div class="icon-wrapper">
        <UIcon name="chevron-bottom" />
      </div>
    </div>
    <div class="select-dropdown-list custom-scrollbar">
      <template v-if="list.length">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="select-dropdown-list-item"
          @click="() => (selectedItem = item)"
        >
          <div v-if="item.color" class="color" :style="{ background: item.color }"></div>
          <div class="select-dropdown-list-item-title">
            {{ item[titleKey] }}
          </div>
        </div>
      </template>
      <template v-else>
        <p v-if="!type" >Совпадений не найдено</p>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SelectSearch',
  props: {
    type: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    titleKey: {
      type: String,
      default: 'title'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [Object, String],
      required: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      state: false,
      list: [],
      selectedItem: null,

      // search
      deBounceTimeout: null,
      deBounce: 450,
      searchString: '',
      focusSearch: false
    };
  },
  computed: {
    selected: {
      get() {
        if (this.value) {
          this.setSearchString(this.value[this.titleKey]);
        }
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    focusSearch() {
      if (!this.focusSearch) {
        setTimeout(() => {
          if (this.selectedItem) {
            this.selected = this.selectedItem;
            this.searchString = this.selectedItem[this.titleKey];
          }

          this.closeMenu();
        }, 300);
      }
    },
    selected(value) {
      if (!value) {
        this.searchString = '';
      }
    },
    items(value) {
      this.list = value;
    }
  },
  mounted() {
    this.list = this.items;
  },
  methods: {
    setSearchString(value) {
      this.searchString = value;
    },
    onSearch(e) {
      this.state = true;
      const value = e === undefined ? '' : e.target.value;
      this.searchString = value;

      clearTimeout(this.deBounceTimeout);
      this.deBounceTimeout = setTimeout(() => {
        if (value) {
          this.list = this.items.filter((item) => {
            return item[this.titleKey]
              .toLowerCase()
              .includes(value.toLowerCase());
          });
        } else {
          this.list = this.items;
        }
      }, this.deBounce);
    },
    closeMenu() {
      this.state = false;
    },
    toggleMenu() {
      this.state = !this.state;
    }
  }
};
</script>

<style lang="scss" scoped>
.select-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    border: none !important;
    background: none;
  }

  .search-dropdown {
    height: 100%;
    width: 100%;
    outline: none;
  }

  label {
    color: #a8a8a8;
    font-size: 14px;
    transition: all ease-in-out 0.2s;
    margin-bottom: 8px;
  }

  .toggle {
    display: flex;
    align-items: center;
    position: relative;
    background: #FAFAFA;
    border-radius: 6px;
    height: 48px;
    line-height: 48px;
    padding: 0 40px 0 10px;
    text-align: left;
    font-family: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
    color: #505050;

    .icon-wrapper {
      height: 48px;
      width: 48px;
      margin: 0 5px 0 10px;
      fill: var(--accent-color);
      pointer-events: none;
      transition: all 0.2s;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        fill: #626262;
      }
    }

    .color {
      min-width: 28px;
      min-height: 28px;
      border-radius: 6px;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
    }

    .placeholder {
      color: #a8a8a8;
      font-weight: 400;
    }
  }

  .select-dropdown-list {
    z-index: 2;
    display: none;
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    flex-direction: column;
    top: 100%;
    border: 1px solid #e5e5e5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 10px;
    background-color: #fff;

    &-item {
      display: flex;
      align-items: center;
      height: 50px;
      min-height: 50px;
      line-height: 50px;
      color: #505050;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:not(:first-child) {
        border-top: 1px solid #e5e5e5;
      }
      &:hover {
        cursor: pointer;
      }

      .color {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }

    p {
      padding: 20px;
      text-align: center;
      color: var(--support-dark-gray);
    }
  }

  &[data-open='true'] {
    .toggle {
      font-weight: 500;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #e5e5e5;
        height: 1px;
        margin-left: -10px;
      }
      .icon-wrapper {
        transform: rotate(180deg);
      }
    }
    .select-dropdown-list {
      display: flex;
    }
  }

  &[data-invalid='true'] {
    &:not([data-open='true']) {
      label {
        color: #fb3434;
      }
      .toggle {
        transition: border-color ease-in-out 0.2s;
        border-color: #fb3434;
      }
    }
  }

  &[data-variant='primary'] {
    .toggle {
      svg {
        path {
          fill: #347afb;
        }
      }
    }
  }

  &[data-variant='gosuslugi'] {
    .toggle {
      svg {
        path {
          fill: #0063b0;
        }
      }
    }
  }
}
</style>
