<template>
  <div v-if="!isLoading" class="course-page">
    <header>
      <div class="back" @click="back">
        <UIcon name="arrow-left" />
      </div>
      <div class="title">{{ isCreate ? 'Создание курса' : 'Изменение курса' }}</div>
      <UButton @click="actionCourse">
        {{ isCreate ? 'Создать курс' : 'Сохранить курс'}}
      </UButton>
    </header>

    <div class="wrapper">
      <div class="course-about">
        <div class="course-wrapper-about">
          <div class="title">О курсе</div>

          <Input
            v-model="course.title"
            id="title"
            placeholder="Название курса"
          />

          <Input
            v-model="course.description"
            id="description"
            placeholder="Описание курса"
          />

          <div class="!mb-8 flex items-center cursor-pointer">
            <input type="checkbox" id="step" name="step" class="w-5 h-5" v-model="course.isStepByStep" />
            <label for="step" class="text-base !ml-2 !mb-0">Пошаговое прохождение курса</label>
          </div>

          <div class="users">
            <div class="title">Выберите сотрудников для доступа к курсам</div>
            <SelectDropdownSearch
              v-model="choseUser"
              :items="users"
              title-key="name"
              placeholder="Добавить сотрудника"
            />

            <div class="list-useres custom-scrollbar">
              <div
                v-for="(user, index) of listUsers"
                :key="index"
                class="user"
              >
                <div>
                  <div class="initial">{{ user.initials }}</div>
                  <div class="name">{{ user.name }}</div>
                </div>
                <div class="close" @click="listUsers.splice(index, 1)">
                  <UIcon name="close" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer v-if="!isCreate">
          <div class="block" @click="deleteCourse">Удалить курс</div>
          <div class="block" @click="deactiveCourse">
            {{ course.active ? 'Отключить курс' : 'Включить курс' }}</div>
        </footer>
      </div>
      <CourseStructure v-model="course.structure" />
    </div>
  </div>
  <UPreloader v-else />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { coreApi } from '@/api'
import router from '@/router'
import CourseStructure from '@/components/redesign/course/structure/CourseStructure'
import UButton from '@/components/redesign/ui/UButton'
import UPreloader from '@/components/redesign/ui/UPreloader';
import Input from '@/components/redesign/form/FormInput'
import SelectDropdownSearch from '@/components/redesign/form/SelectDropdownSearch'

export default {
  name: 'CoursePage',
  components: {
    UButton,
    UPreloader,
    CourseStructure,
    Input,
    SelectDropdownSearch,
  },
  data() {
    return {
      isLoading: true,
      course: {},
      choseUser: {},
      listUsers: [],
    };
  },
  computed: {
    ...mapState('main', ['user']),
    ...mapState('courses', ['employees']),
    isCreate() {
      return !(router.currentRoute.params.id > 0);
    },
    users() {
      const users = [...this.employees];
      const resUsers = [];

      for (const user of users) {
        const users = this.listUsers.filter(item => item.id == user.id);
        if (!users.length) resUsers.push(user);
      }

      return resUsers;
    },
  },
  watch: {
    choseUser() {
      if (this.choseUser?.id) {
        this.listUsers.push(this.choseUser);
        this.choseUser = {};
      }
    },
  },
  methods: {
    ...mapActions('courses', ['initCourse']),
    async init() {
      this.isLoading = true;
      this.course = await this.initCourse(router.currentRoute.params.id);

      console.log('this.course', this.course);
      this.listUsers = this.course.employees;
      this.isLoading = false;
    },
    async actionCourse() {
      if (!this.course.title) {
        alert('Название курса обязательное поле');
        return;
      }

      const params = {
        user: this.user,
        course: {
          ...this.course,
          // isStepByStep: this.course.isStepByStep === "0" ? false : true,
          employees: this.listUsers?.map((item) => item.id) || [],
          structure: this.course.structure?.map((item) => `${item.id} - ${item.type}`).join(' | ') || '',
        },
      };

      if (this.course.id) await coreApi.courseUpdate(params);
      else coreApi.courseCreate(params);

      this.back();
    },
    back() {
      this.$router.replace(`/courses`);
    },
    async deleteCourse() {
      await coreApi.courseDelete(router.currentRoute.params.id);
      this.$router.replace(`/courses/`);
    },
    deactiveCourse() {
      this.course.active = !this.course.active;
      this.actionCourse();
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>


<style lang="scss">
.course-page {
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .back {
      min-width: 48px;
      width: 48px;
      height: 48px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-right: 24px;
      cursor: pointer;

      .icon {
        fill: #A9A9A9;
      }

      &:hover {
        .icon {
          fill: #777777;
        }
      }
    }

    & > .title {
      width: 100%;
      font-weight: 600;
      font-size: 24px;
      line-height: 18px;
    }

    .button {
      height: 48px;
      max-width: 220px;
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-start;

    .course-about {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      margin-right: 16px;

      .course-wrapper-about {
        padding: 30px;
        margin-bottom: 40px;

        & > .title {
          font-weight: 600;
          font-size: 22px;
          line-height: 18px;
          margin-bottom: 24px;
        }

        & > .form-input {
          margin-bottom: 32px;
        }

        & > .users {
          & > .title {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 16px;
          }

          .list-useres {
            margin-top: 24px;
            max-height: 300px;
            overflow: auto;

            .user {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 48px;
              background: #FAFAFA;
              border-radius: 5px;
              padding: 0 16px 0 12px;
              margin-bottom: 8px;

              & > div {
                display: flex;
                align-items: center;
              }

              .initial {
                font-size: 13px;
                font-weight: 500;
                width: 32px;
                height: 32px;
                color: #006CB7;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
              }

              .name {
                font-weight: 400;
                  font-size: 15px;
                  line-height: 18px;
              }

              .close {
                cursor: pointer;
                height: 48px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                  width: 13px;
                  height: 13px;
                  fill: #a7a7a7;
                }
              }
            }
          }
        }
      }

      footer {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 61px;
        border-top: 1px solid #DCDCDC;
        overflow: hidden;
        border-radius: 0 0 10px 10px;

        .block {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #DCDCDC;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          color: #ABABAB;

          &:last-child {
            border-right: 0;
          }

          &:hover {
            cursor: pointer;
            background: #fdfdfd;
            color: #434343;
          }
        }
      }
    }
  }
}
</style>
