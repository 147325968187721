<template>
  <div class="course-structure">
    <header>
      <div class="title">
        Содержание курса
      </div>
      <p>Созадайте содержание курса используя тесты и материалы</p>
    </header>

    <div class="structure">
      <draggable
        :list="structure"
        class="list-group"
        v-bind="dragOptions"
        @start="dragging = true"
        @end="dragging = false"
      >
        <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
          <div
            v-for="(block, index) of structure"
            :key="index"
            class="block"
          >
            <div class="info">
              <div class="title">
                <span class="num">{{ index + 1 }}</span> {{ block.title }}
              </div>
              <div
                class="type"
                :class="[block.type]"
              >
                {{ block.type === 'material' ? 'Материал' : 'Тест' }}
              </div>
            </div>

            <div class="actions">
              <div
                class="delete"
                @click="deleteItemStructure(index)"
              >
                Удалить
              </div>
              <div
                class="change"
                @click="changeItemStructure(block)"
              >
                Изменить
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
      <div
        v-if="!structure.length"
        class="block empty-block"
      >
        Нажмите на <span>+</span> чтобы добавить
        <br>
        тест или материал
      </div>

      <div
        class="add-block"
        @click="isModalChoseEntity = true"
      >
        <UIcon name="plus-circle" />
      </div>

      <div class="line-vertical" />
    </div>

    <UModal
      v-model="isModalChoseEntity"
      close
    >
      <ChooseEntityWrapper @addEntity="addEntity($event)" />
    </UModal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import UModal from '@/components/redesign/ui/UModal'
import ChooseEntityWrapper from '@/components/redesign/base/choose-entity/ChooseEntityWrapper'

export default {
  name: 'CourseStructure',
  components: { ChooseEntityWrapper, UModal, draggable },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isModalChoseEntity: false,
      dragging: false,
    }
  },
  computed: {
    structure: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    addEntity(entity) {
      this.structure.push(entity)
      this.isModalChoseEntity = false
    },
    deleteItemStructure(index) {
      this.structure.splice(index, 1)
    },
    changeItemStructure(block) {
      let path = `/article/${block.id}`

      if (block.type === 'test') path = `/test/${block.id}`
      window.open(path, '_blank')
    },
    onList(list) {
      console.log('list', list);
    }
  },
}
</script>

<style lang="scss">
.course-structure {
  width: 100%;
  padding: 30px;
  background: #fff;
  border-radius: 10px;

  header {
    margin-bottom: 40px;

    & > .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 18px;
    }

    p {
      margin-top: 12px;
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #717171;
    }
  }

  .structure {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .block {
      position: relative;
      z-index: 2;
      margin-bottom: 60px;
      cursor: pointer;

      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 16px;
        width: 220px;
        cursor: move;
        z-index: 2;
        background: #F9F9F9;

        .title {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 4px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .num {
          background: #0088cc;
          width: 20px;
          height: 20px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 500;
          border-radius: 4px;
          font-size: 12px;
          margin-right: 4px;
        }

        .type {
          height: 20px;
          padding: 0 13px;
          border-radius: 100px;
          width: max-content;

          &.material {
            background: rgba(0, 136, 204, 0.1);
            color: #0088CC;
          }

          &.test {
            background: rgba(8, 181, 46, 0.1);
            color: #08B52E;
          }
        }
      }

      .actions {
        position: absolute;
        overflow: hidden;
        display: flex;
        width: 100%;
        border-radius: 0 0 10px 10px;
        top: 20px;
        z-index: -1;
        transition: all .3s;

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 20px 0 10px 0;
          font-size: 13px;
          font-weight: 500;
          cursor: pointer;

          &.delete {
            background: #FCEFEF;
            color: #E26262;

            &:hover {
              background: #FFE2E2;
            }
          }

          &.change {
            background: #E6F3FA;
            color: #0088CC;

            &:hover {
              background: #D2EFFF;
            }
          }
        }
      }

      &.empty-block {
        font-weight: 500;
        padding: 20px 0 10px 0;
        background: #fff;
        font-size: 16px;
        text-align: center;

        span {
          color: #0088CC;
        }
      }

      &:hover {
        .actions {
          top: 65px;
        }
      }
    }

    & > .add-block {
      z-index: 2;
      cursor: pointer;
      transition: all .3s;

      .icon {
        fill: #0088CC;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .line-vertical {
      position: absolute;
      top: 0;
      height: 100%;
      border: 1px dashed #e7e7e7;
      z-index: 1;
    }
  }
}
</style>
