<template>
  <div class="choose-entity-wrapper">
    <header>
      <div class="title">Выбор статьи и теста</div>
      <p>Выберите или создайте тест или статью для курса</p>
    </header>

    <div class="tabs">
      <div
        v-for="(tab, index) of tabs"
        :key="index"
        class="tab"
        :class="{ active: tabActive === index }"
        @click="choseTab(index)"
      >
        {{ tab.title }}
      </div>
    </div>

    <div class="search">
      <input type="text" v-model="search" :placeholder="placeholderSearch" />
    </div>
  
    <div class="list custom-scrollbar">
      <div
        v-for="(item, index) of list"
        :key="index"
        class="item"
        :class="{ active: listItemActive === index }"
        @click="listItemActive = index"
      >
        <div class="title">{{ item.title }}</div>
        <p>{{ item.description }}</p>
      </div>
    </div>

    <div class="bottom">
      <UButton @click="create" variant="soft">
        {{ this.tabActive ? 'Создать тест' : 'Создать статью'}}
      </UButton>
      <UButton @click="chose" :disabled="listItemActive == null">
        Выбрать
      </UButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UButton from '@/components/redesign/ui/UButton'

export default {
  name: 'ChooseEntityWrapper',
  components: { UButton },
  data() {
    return {
      search: '',
      tabActive: 0,
      tabs: [
        {
          title: 'Cтатьи',
          components: 'articles'
        },
        {
          title: 'Тесты',
          components: 'tests'
        }
      ],
      listItemActive: null,
      list: []
    }
  },
  watch: {
    search(value) {
      const list = this.tabActive ? this.tests : this.articles
      
      this.list = list.filter((item) => 
        item.title.toLowerCase().includes(value) || item.description.toLowerCase().includes(value)
      )
    }
  },
  computed: {
    ...mapState('main', ['tests', 'articles']),
    placeholderSearch() {
      return this.tabActive ? 'Поиск по тестам' : 'Поиск по статьям'
    }
  },
  methods: {
    choseTab(index) {
      this.search = ''
      this.tabActive = index
      this.listItemActive = null
      this.list = this.tabActive ? this.tests : this.articles
    },
    create() {
      let path = '/article/0'

      if (this.tabActive) path = '/test/0'
      window.open(path, '_blank');
    },
    chose() {
      const currentEntity = (this.tabActive ? this.tests : this.articles)[this.listItemActive]
      this.$emit('addEntity', {
        ...currentEntity,
        type: this.tabActive ? 'test' : 'material'
      })
    }
  },
  mounted() {
    this.list = this.articles
  }
}
</script>

<style lang="scss">
.choose-entity-wrapper {
  width: 100%;

  header {
    margin-bottom: 24px;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 4px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      color: #838383;
    }
  }

  .tabs {
    display: flex;
    width: 100%;
    height: 48px;
    background: #FAFAFA;
    border-radius: 8px;
    margin-bottom: 16px;

    .tab {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      height: 100%;
      cursor: pointer;

      &.active {
        background: #0088CC;
        color: #fff;
      }
    }

    &:hover {
      background: #F3F3F3;
    }
  }

  .search {
    margin-bottom: 16px;

    input {
      background: #FAFAFA;
      height: 48px;
      border: none;
      border-radius: 8px;
      padding: 10px 16px;
      width: 100%;
    }
  }

  .list {
    height: 320px;
    overflow: auto;
    padding-right: 10px;
    margin-bottom: 24px;

    .item {
      padding: 12px 16px;
      border-radius: 8px;
      background: #FAFAFA;
      margin-bottom: 8px;
      cursor: pointer;

      .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #ADADAD;
      }

      &:hover {
        background: #F6F6F6;
      }

      &.active {
        background: #0088CC;
        
        .title {
          color: #fff;
        }

        p {
          color: rgba(255, 255, 255, 0.62);
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;

    .button {
      font-size: 14px;

      &:first-child {
        width: max-content;
      padding: 0 40px;
      margin-right: 12px;
      }

      &:last-child {
        width: 160px;
      }
    }
  }
}
</style>